import { h, Component } from 'preact';
import styled from '@emotion/styled';
import GetLinkUrl from '../../utils/getLinkUrl';
const Root = styled.footer`
  border-top: 1px solid #efefef;
  background: #f8f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 38px;
  justify-content: space-between;
`;

const Label = styled.span`
  color: #525252;
  font-size: 12px;
  margin-left: 22px;
`;

const Logo = styled.img`
  margin-right: 22px;
`;
export default class Footer extends Component {
  render() {
    const { shortName = 'none', env } = this.props;
    let linkUrl = GetLinkUrl(shortName, env);
    return (
      <Root>
        <Label>Powered by:</Label>
        <a href={linkUrl} target="_blank" rel="noopener">
          <Logo
            src={'https://widget.crediblemind.com/assets/logo-widget.svg'}
            alt="Thrive Mentally and Spiritually - Expert and user rated resources for a better life - CredibleMind"
          />
        </a>
      </Root>
    );
  }
}
