import { h, Component } from 'preact';
import HelpMe from './HelpMe/index';
import Search from './search/index';
import Container from './styles/Container';
import Root from './styles/Root';
import Title from './styles/Title';
import Footer from './Footer/index';
import TextDivider from './TextDivider/index';
import GqlClient from '../services/graphqlService';
import { GET_WIDGET_DATA } from '../services/widgets.gql';
import isEmpty from '../utils/isEmpty';
import get from '../utils/get';
import Header from './Header/index';
import HeaderTitle from './styles/Header';

export default class App extends Component {
  state = {
    widgetInfo: undefined,
  };
  async componentDidMount() {
    const { name, env = '' } = this.props;
    if (name) {
      const variables = {
        slug: name || '',
      };
      const data = await GqlClient(env).request(GET_WIDGET_DATA, variables);
      this.setState({
        widgetInfo: data.widgetsCollection,
      });
    }
  }
  render(props) {
    const { widgetInfo } = this.state;
    const widgetItems = get(widgetInfo, 'items');
    const hasDesign = get(widgetInfo, 'items.0.design');
    const hasClient = get(widgetInfo, 'items.0.client');
    const hasItems = !isEmpty(widgetItems) && widgetItems !== undefined;
    const hasWidget = props.name && hasItems;
    if (props.name && !hasItems) return null;
    const finalProps =
      hasWidget && hasDesign ? widgetInfo.items[0].design : props;
    const type = hasWidget ? widgetInfo.items[0].type : props.type;
    const finalType = type || 'combo';
    const topTopics = hasWidget
      ? get(widgetInfo.items[0], 'topTopicsCollection.items')
      : [];
    const excludedTopics = hasWidget
      ? get(widgetInfo.items[0], 'client.excludeTopicCollection.items')
      : [];
    const shortName = hasWidget
      ? get(widgetInfo.items[0], 'client.shortName')
      : '';
    const { env = 'PROD', withlogo = false, responsive = false } = props;
    const {
      title = 'Mental Health Resources',
      stripeColor,
      bgColor,
      width,
      height,
      buttonBGColor,
      buttonTextColor,
      searchText,
      sentenceJoin,
      sentencePrompt,
      submitText,
      helpMeTextColor,
      titleTextColor,
    } = finalProps;
    const searchProps = {
      searchText,
      type: finalType,
      excludedTopics,
      env,
      shortName,
    };
    const helpMeProps = {
      buttonBGColor,
      buttonTextColor,
      sentenceJoin,
      sentencePrompt,
      submitText,
      type: finalType,
      topTopics,
      excludedTopics,
      helpMeTextColor,
      env,
      shortName,
    };
    const rootProps = {
      width,
      height,
      stripeColor,
      bgColor,
      responsive: responsive === 'true',
    };
    const hasLogo = withlogo === 'true';
    const showCompanyHeader = hasClient && hasLogo;
    return (
      <Root {...rootProps}>
        <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Mada:400,700&display=swap" />
        {showCompanyHeader && (
          <Header data={widgetInfo.items[0].client} />
        )}
        {!showCompanyHeader && <HeaderTitle title={title} color={titleTextColor} />}
        <Container>
          {showCompanyHeader && <Title color={titleTextColor}>{title}</Title>}
          {['combo', 'search'].includes(finalType) && (
            <Search {...searchProps} />
          )}
          {finalType === 'combo' && <TextDivider />}
          {['combo', 'help_me'].includes(finalType) && (
            <HelpMe {...helpMeProps} />
          )}
        </Container>
        <Footer env={env} shortName={shortName} />
      </Root>
    );
  }
}
