export default {
  DEV: {
    ENV: 'development',
    ALGOLIA: {
      APPLICATION_ID: 'GICLK1ZZ6G',
      SEARCH_API_KEY: 'a69aadce1c3a5e1ba871a8a4cca47674',
      RESOURCES_INDEX: 'dev_resources',
      SEARCH_INDEX: 'dev_search'
    },
    CONTENTFUL: {
      CDN_URL: 'cdn.contentful.com',
      SPACE_ID: 'o6q5esfvflvg',
      ACCESS_TOKEN:
        'bd40d8de4d5f0505bcf12909ada11b1115508ae4007c3957e1b7a9dfe3634a29',
      ENVIRONMENT: 'dev',
    },
    URL: 'https://romantic-bhabha-a4590e.netlify.com'
  },
  PROD: {
    RELEASE: 'V1',
    ENV: 'production',
    ALGOLIA: {
      APPLICATION_ID: 'GICLK1ZZ6G',
      SEARCH_API_KEY: 'a69aadce1c3a5e1ba871a8a4cca47674',
      RESOURCES_INDEX: 'resources',
      SEARCH_INDEX: 'search'
    },
    CONTENTFUL: {
      CDN_URL: 'cdn.contentful.com',
      SPACE_ID: 'o6q5esfvflvg',
      ACCESS_TOKEN:
        'bd40d8de4d5f0505bcf12909ada11b1115508ae4007c3957e1b7a9dfe3634a29',
      ENVIRONMENT: 'master',
    },
    URL: 'https://crediblemind.com'
  },
};
