export const GET_TOPICS = /* GraphQL */ `
  query Topics($excludedTopicId: [String!]!) {
    topicsCollection(
      where: {
        sys: { id_not_in: $excludedTopicId }
        reviewStatus: "Accepted"
        userRating_exists: true
        type_in: ["Minor", "Major"]
      }
      order: [userRating_DESC]
    ) {
      items {
        title
        slug
      }
    }
  }
`;
