import { h, Component } from 'preact';
import styled from '@emotion/styled';

const Root = styled.div`
  width: ${props => props.name && props.name.toUpperCase() === 'PROVIDENCE' ? '100%' : `calc(100% - 44px)`};
  max-block-size:  ${props => props.name && props.name.toUpperCase() === 'PROVIDENCE' ? '175px' : '305px'};
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 0 41px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: ${props => props.name && props.name.toUpperCase() === 'PROVIDENCE' ? 'relative' : 'absolute'};
  z-index: 999;
`;

const Ul = styled.ul`
  list-style: none !important;
  padding: 0 !important;
  margin: 10px 0 !important;
`;

const LI = styled.li`
  height: 32px;
  padding: 0 20px !important;
  margin: 0 !important;
  display: flex;
  font-family: 'Mada' !important;
  align-items: center;
  color: ${(props) => (props.active ? '#01619B !important' : '#4b4b4b !important')};
  font-size: 14px important;
  font-weight: ${(props) => (props.active ? 600 : 400)};
  cursor: pointer;
  :hover {
    background: #f8f9f9;
  }
`;
export default class DropDownTopics extends Component {
  render() {
    const { open, data, clickHandler, selected, name } = this.props;
    if (!open) return null;
    return (
      <Root name={name}>
        <Ul>
          {data.map((item) => (
            <LI
              onClick={() => clickHandler(item)}
              active={selected === item.title}
            >
              {item.title}
            </LI>
          ))}
        </Ul>
      </Root>
    );
  }
}
