import habitat from 'preact-habitat';

import Widget from './components/widget';

let _habitat = habitat(Widget);

_habitat.render({
  clientSpecified: true,
  clean: true,
});
