import { h, Component } from 'preact';
import styled from '@emotion/styled';
import { hasHash } from '../../utils/propsChecker';

const ArrowDownIcon =
  'https://widget.crediblemind.com/assets/arrow-down-gray.svg';
const ArrowUpIcon = 'https://widget.crediblemind.com/assets/arrow-up-gray.svg';
const Root = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid #efefef;
  height: 33px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 6px;
`;
const Label = styled.span`
  font-size: 12px;
  color: ${(props) => {
    const propsHasHash = hasHash(props.color);
    if (props.color && propsHasHash) {
      return props.color;
    } else if (props.color && !propsHasHash) {
      return `#${props.color}`;
    } else {
      return '#4b4b4b';
    }
  }};
  font-weight: 400;
`;

const ArrowDown = styled.img`
  position: absolute;
  right: 0;
  height: 5px;
`;
export default class Selector extends Component {
  render() {
    const { value, clickHandler, open, color } = this.props;
    return (
      <Root role="button" onClick={clickHandler}>
        <Label color={color}>{value}</Label>
        <ArrowDown src={open ? ArrowUpIcon : ArrowDownIcon} alt="Arrow Down"/>
      </Root>
    );
  }
}
