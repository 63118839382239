import { h, Component } from 'preact';
import styled from '@emotion/styled';

const Root = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #efefef;
  line-height: 0.1em !important;
  font-family: 'Mada' !important;
  color: #525252;
  font-size: 12px !important;
  margin-top: 25px !important;
  margin-bottom: 15px !important;
  padding: 0 !important;
  span {
    background: #fff;
    padding: 0 20px;
  }
`;
export default class TextDivider extends Component {
  render() {
    return (
      <Root>
        <span>OR</span>
      </Root>
    );
  }
}
