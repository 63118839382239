import { GraphQLClient } from 'graphql-request';
import Config from './config';

const getGraphQLClient = (env) => {
  const { SPACE_ID, ACCESS_TOKEN } = Config[
    env.toUpperCase() || 'PROD'
  ].CONTENTFUL;
  const gqlEnv = env.toLowerCase() === 'dev' ? 'dev' : 'master';
  const uri = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${gqlEnv.toLowerCase()}`;
  const graphQLClient = new GraphQLClient(uri, {
    headers: {
      authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  });
  return graphQLClient;
};

export default getGraphQLClient;
