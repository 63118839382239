import { h, Component } from 'preact';
import styled from '@emotion/styled';
import { ITEMS } from './constants';
const Root = styled.div`
  width: ${props => props.name && props.name.toUpperCase() === 'PROVIDENCE' ? '100%' : `calc(100% - 44px)`};
  max-block-size:  ${props => props.name && props.name.toUpperCase() === 'PROVIDENCE' ? '225px' : '305px'};
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 0 41px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: ${props => props.name && props.name.toUpperCase() === 'PROVIDENCE' ? 'relative' : 'absolute'};
  z-index: 999;
`;

const Ul = styled.ul`
  list-style: none !important;
  padding: 0 !important;
  margin: 10px 0 !important;
`;

const LI = styled.li`
  font-size: 14px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 35px;
  margin: 0 !important;
  padding: 0 26px !important;
  font-family: 'Mada' !important;
  color: ${(props) => (props.active ? '#01619B !important' : '#4b4b4b !important')};
  font-weight: ${(props) => (props.active ? 600 : 400)};
  border-bottom: ${(props) => (props.hasBorder ? '1px solid #E9ECED' : 'none')};
  :hover {
    background: #f8f9f9;
  }
`;

const Icon = styled.img`
  margin-right: 17px;
  width: 14px;
  height: 12px;
`;

export default class DropDownTypes extends Component {
  render() {
    const { open, selected, clickHandler, hasApps, hasPodcasts, hasPrograms, name } = this.props;
    if (!open) return null;
    return (
      <Root name={name}>
        <Ul>
          {ITEMS.map((item, index) => {
            if (item.value === 'Apps' && !hasApps) {
              return null;
            }
            if (item.value === 'Podcasts' && !hasPodcasts) {
              return null;
            }
            if (item.value === 'Online Programs' && !hasPrograms) {
              return null;
            }
            return (
              <LI
                onClick={() => clickHandler(item.value)}
                active={selected === item.value}
                hasBorder={index === 0}
              >
                <Icon src={item.icon[selected === item.value ? 1 : 0]} alt={item.label} />
                {item.label}
              </LI>
            );
          })}
        </Ul>
      </Root>
    );
  }
}
