import styled from '@emotion/styled';

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px 22px 0 22px;
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
`;
export default Container;
