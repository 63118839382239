import styled from '@emotion/styled';

export const Title = styled.span`
  font-size: 13px;
  color: #777;
  padding: 10px 0 0 10px;
  font-family: 'Mada' !important;
`;

export const SearchOptionWrapper = styled.div`
  display: flex;
  text-decoration: none;
`;

export const SearchOption = styled.span`
  color: #000;
  flex: 1;
  margin: 0;
  line-height: 24px !important;
  font-size: 15px !important;
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 40px;
  right: 0;
  top: 0;
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Progress = styled.img`
  width: 80px;
`;

export const SearchIcon = styled.img`
  height: 16px;
  width: 16px;
`;
