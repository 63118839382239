import { h, render, Component } from "preact";
import Autosuggest from "react-autosuggest";
import Config from "../../services/config";
import AlgoliaClient from "../../services/algoliaService";
import Wrapper from "./Wrapper";
import SuggestionIcon from "./SuggestionIcon";
import GetLinkUrl from "../../utils/getLinkUrl";
import {
  Title,
  SearchOptionWrapper,
  SearchOption,
  IconContainer,
  Progress,
  SearchIcon,
} from "./styles";

const initialState = {
  value: "",
  original: "",
  suggestions: [],
  processing: false,
  original: "",
};

export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    const { env, shortName } = props;
    const rootUrl = GetLinkUrl(shortName, env);
    this.state = {
      ...initialState,
      rootUrl,
    };
  }

  onChange = (_, { newValue }) => {
    this.setState({
      value: newValue,
      original: newValue,
      processing: newValue.length > 1,
    });
  };

  onKeyDown = (e) => {
    const { value, processing, rootUrl } = this.state;
    if (e.key === "Enter" && processing) {
      const finalUrl = `${rootUrl}/resources?query=${value}&search-term=${value}`;
      window.open(finalUrl, "_newtab");
      this.setState(initialState);
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      this.fetchSuggestions(value);
    }, 300);
  };

  fetchSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const { env = "" } = this.props;
    const inputLength = inputValue.length;
    if (inputLength > 1) {
      const {
        ALGOLIA: { SEARCH_INDEX },
      } = Config[env.toUpperCase() || "PROD"];

      const searchIndex = AlgoliaClient(env).initIndex(SEARCH_INDEX);
      searchIndex.search({ query: inputValue }, (err, content) => {
        if (err) throw err;
        let newSuggestions = [];
        const { excludedTopics } = this.props;
        const topics = content.hits.filter((hit) => hit.type === "Topics");
        const excludedTopicId =
          excludedTopics.length > 0
            ? excludedTopics.map(({ sys }) => sys.id)
            : [];
        const topicResultsIndex0 = topics.filter(
          (el) => !excludedTopicId.includes(el.objectID)
        );
        const topicResultsIndex1 = topics.filter(
          (el) => !excludedTopicId.includes(el.objectID)
        );
        const filteredTopics = topicResultsIndex0.filter(
          (el) =>
            el.nameTitle
              .toLowerCase()
              .split(" ")
              .includes(inputValue.toLowerCase()) ||
            el._highlightResult.nameTitle.fullyHighlighted
        );
        const filteredTopicsByAlias = topicResultsIndex1.filter(
          (el) =>
            (el.aliases &&
              el.aliases
                .map((alias) =>
                  alias
                    .toLowerCase()
                    .split(" ")
                    .includes(inputValue.toLowerCase())
                )
                .some((alias) => alias === true)) ||
            (el._highlightResult.aliases &&
              el._highlightResult.aliases
                .map((alias) => alias.fullyHighlighted)
                .includes(true))
        );
        let firstOptions;
        if (filteredTopics.length > 0) {
          firstOptions = [
            {
              nameTitle: `Go to ${filteredTopics[0].nameTitle} Topic Center`,
              type: "topicCenter",
              url: `/topics/${filteredTopics[0].slug}`,
            },
            {
              nameTitle: `All Resources matching "${filteredTopics[0].nameTitle}"`,
              type: "resourcesPage",
              url: `/resources?query=${inputValue}`,
            },
          ];
        } else if (filteredTopicsByAlias.length > 0) {
          const title = filteredTopicsByAlias[0]._highlightResult.aliases
            .filter((el) => el.matchedWords.includes(inputValue))[0]
            .value.replace(/\<(\/)?(\w)*(\d)?\>/g, "");
          firstOptions = [
            {
              nameTitle: `Go to ${title} (${filteredTopicsByAlias[0].nameTitle}) Topic Center`,
              type: "topicCenter",
              url: `/topics/${filteredTopicsByAlias[0].slug}`,
            },
            {
              nameTitle: `All Resources matching "${inputValue}"`,
              type: "resourcesPage",
              url: `/resources?query=${inputValue}`,
            },
          ];
        } else {
          firstOptions = [
            {
              nameTitle: `All Resources matching "${inputValue}"`,
              type: "resourcesPage",
              url: `/resources?query=${inputValue}`,
            },
          ];
        }
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          firstOptions,
          null,
          null
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Topics"),
          "Topics",
          "topic"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Videos"),
          "Videos",
          "video"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Podcasts"),
          "Podcasts",
          "podcast"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Apps"),
          "Apps",
          "app"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "People"),
          "People",
          "person"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Books"),
          "Books",
          "book"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Organizations"),
          "Organizations",
          "organization"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Articles"),
          "Articles",
          "article"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "News"),
          "News",
          "news"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Assessments"),
          "Assessments",
          "assessment"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Lists"),
          "Lists",
          "list"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "FAQs"),
          "FAQs",
          "faq"
        );
        newSuggestions = addSectionToSuggestions(
          newSuggestions,
          content.hits.filter((hit) => hit.type === "Online Programs"),
          "Onlines Programs",
          "programs"
        );
        this.setState({
          suggestions: newSuggestions,
          processing: false,
        });
      });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (_, { suggestion }) => {
    const { rootUrl, value } = this.state;
    const { shortName = "none" } = this.props;
    const searchTerm = value;
    const { url, type } = suggestion;
    const searchTermOperator = type === "resourcesPage" ? `&` : "?";
    const finalUrl = `${rootUrl}${url}${searchTermOperator}search-term=${searchTerm}`;
    this.setState(initialState);
    window.open(finalUrl, "_newtab");
  };

  renderSectionTitle = ({ title }) => (title ? <Title>{title}</Title> : null);

  renderSuggestion = ({ url, type, title }) => (
    <SearchOptionWrapper to={`${url}`}>
      <SuggestionIcon type={type} />
      <SearchOption>
        {type === "topic" ? `${title} (topic)` : title}
      </SearchOption>
    </SearchOptionWrapper>
  );

  renderInputComponent = (inputProps) => (
    <input
      onInput={inputProps.onChange}
      {...inputProps}
      aria-label="Input Search"
    />
  );

  render() {
    const { value, suggestions, processing } = this.state;
    const { type, searchText = "I am looking for..." } = this.props;
    const inputProps = {
      placeholder: searchText,
      value,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
    };
    const searchOnly = type === "search";
    return (
      <Wrapper searchOnly={searchOnly}>
        <Autosuggest
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          focusInputOnSuggestionClick={false}
          highlightFirstSuggestion={false}
          multiSection
          renderSectionTitle={this.renderSectionTitle}
          getSectionSuggestions={(section) => section.suggestions}
          getSuggestionValue={(suggestion) => suggestion.title}
          suggestions={suggestions}
          renderSuggestion={this.renderSuggestion}
          renderInputComponent={this.renderInputComponent}
          inputProps={inputProps}
        />
        <IconContainer>
          {processing ? (
            <Progress
              src="https://crediblemind-widget.s3-us-west-2.amazonaws.com/assets/loading.gif"
              alt="Loading Indicator"
            />
          ) : (
            <SearchIcon
              src="https://crediblemind-widget.s3.us-west-2.amazonaws.com/assets/search.svg"
              alt="Search Topics"
            />
          )}
        </IconContainer>
      </Wrapper>
    );
  }
}

const addSectionToSuggestions = (
  suggestions,
  hits,
  sectionTitle,
  suggestionsType
) => {
  if (hits.length > 0) {
    return suggestions.concat({
      title: sectionTitle,
      suggestions: hits.map(({ nameTitle, url, name, type }) => ({
        title: name || nameTitle,
        url,
        type: suggestionsType || type,
      })),
    });
  }
  return suggestions;
};
