import styled from '@emotion/styled';
const AppsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Apps.svg';
const ArticlesIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Articles.svg';
const AssessmentsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Assessments.svg';
const BooksIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Books.svg';
const FAQsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/FAQ.svg';
const ListsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Lists.svg';
const OrganizationsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Organizations.svg';
const PeopleIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/People.svg';
const PodcastsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Podcasts.svg';
const TopicsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Topics.svg';
const VideosIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Videos.svg';
const NewsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/News.svg';
const ProgramsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Programs.svg';

const SuggestionIconContainer = styled.div`
  margin-right: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuggestionIcon = styled.img`
  height: 16px;
  width: 16px;
`;

function SuggestionIcons({ classes, type }) {
  let src;
  let alt;
  if (type === 'app') {
    src = AppsIcon;
    alt = 'App Icon';
  }
  if (type === 'assessment') {
    src = AssessmentsIcon;
    alt = 'Assessment Icon';
  }
  if (type === 'book') {
    src = BooksIcon;
    alt = 'Book Icon';
  }
  if (type === 'organization') {
    src = OrganizationsIcon;
    alt = 'Organization Icon';
  }
  if (type === 'person') {
    src = PeopleIcon;
    alt = 'Person Icon';
  }

  if (type === 'podcast') {
    src = PodcastsIcon;
    alt = 'Podcast Icon';
  }

  if (type === 'article') {
    src = ArticlesIcon;
    alt = 'Article Icon';
  }

  if (type === 'news') {
    src = NewsIcon;
    alt = 'News Icon';
  }

  if (type === 'topic') {
    src = TopicsIcon;
    alt = 'Topic Icon';
  }

  if (type === 'video') {
    src = VideosIcon;
    alt = 'Video Icon';
  }

  if (type === 'list') {
    src = ListsIcon;
    alt = 'List Icon';
  }
  if (type === 'faq') {
    src = FAQsIcon;
    alt = 'FAQ Icon';
  }

  if (type === 'programs') {
    src = ProgramsIcon;
    alt = 'Program Icon';
  }

  if(src !== undefined) {
    return (
      <SuggestionIconContainer>
        <SuggestionIcon src={src} alt={alt} />
      </SuggestionIconContainer>
    );
  }

  return null;
}

export default SuggestionIcons;
