import { h, Component } from 'preact';
import qs from 'qs';
import Config from '../../services/config';
import AlgoliaClient from '../../services/algoliaService';
import { GET_TOPICS } from '../../services/getTopics.gql';
import Label from './Label';
import Wrapper from './Wrapper';
import DropDownTopics from './DropDownTopics';
import DropDownTypes from './DropDownTypes';
import Selector from './Selector';
import Button from '../../components/styles/Button';
import { ITEMS } from './constants';
import Container from './Container';
import GqlClient from '../../services/graphqlService';
import get from '../../utils/get';
import GetLinkUrl from '../../utils/getLinkUrl';

export default class HelpMe extends Component {
  constructor(props) {
    super(props);
    const { topTopics, env, shortName } = props;
    const hasTopTopics = props.topTopics.length > 0;
    
    this.state = {
      showDropDownTopic: false,
      showDropDownTypes: false,
      selectedTopic: hasTopTopics ? props.topTopics[0].title : 'Anxiety',
      selectedTopicSlug: hasTopTopics ? props.topTopics[0].slug : 'anxiety',
      selectedType: 'All Media Types',
      topics: props.topTopics || [],
      rootUrl: GetLinkUrl(shortName, env),
      hasApps: true,
      hasPodcasts: true,
      hasPrograms: false,
    };
  }

  async componentDidMount() {
    const { topTopics, excludedTopics, env } = this.props;
    if (topTopics.length === 0) {
      const excludedTopicId = excludedTopics.map(({ sys }) => sys.id).join(',');
      const variables = {
        excludedTopicId: excludedTopicId || '',
      };
      const data = await GqlClient(env).request(GET_TOPICS, variables);
      const {
        topicsCollection: { items },
      } = data;
      this.setState(
        {
          topics: items,
          selectedTopic: items[0].title || 'Anxiety',
          selectedTopicSlug: items[0].slug || 'anxiety',
        },
        () => {
          this.checkTopicsData(this.state.selectedTopic);
        },
      );
    }
  }

  selectTopic = ({ title, slug }) => {
    this.checkTopicsData(title);
    this.setState({
      selectedTopic: title,
      selectedTopicSlug: slug,
      showDropDownTopic: false,
      showDropDownTypes: false,
    });
  };

  checkTopicsData = async (topic) => {
    const { ALGOLIA } = Config[this.props.env.toUpperCase()];
    const ResourcesIndex = AlgoliaClient(
      this.props.env.toUpperCase(),
    ).initIndex(ALGOLIA.RESOURCES_INDEX);
    const appsData = await ResourcesIndex.search({
      hitsPerPage: 1,
      filters: `reviewStatus:'Accepted' AND NOT type:'Topics' AND NOT type:'Assessments' AND type:'Apps'   AND allTopics.title:'${topic}'`,
    });
    const podcastData = await ResourcesIndex.search({
      hitsPerPage: 1,
      filters: `reviewStatus:'Accepted' AND NOT type:'Topics' AND NOT type:'Assessments' AND type:'Podcasts'   AND allTopics.title:'${topic}'`,
    });
    const onlineProgramsData = await ResourcesIndex.search({
      hitsPerPage: 1,
      filters: `reviewStatus:'Accepted' AND NOT type:'Topics' AND NOT type:'Assessments' AND type:'Online Programs'   AND allTopics.title:'${topic}'`,
    });

    if (get(appsData, 'hits').length === 0) {
      this.setState({
        hasApps: false,
      });
      if (this.state.selectedType === 'Apps') {
        this.setState({
          selectedType: 'All Media Types',
        });
      }
    } else {
      this.setState({
        hasApps: true,
      });
    }
    if (get(podcastData, 'hits').length === 0) {
      this.setState({
        hasPodcasts: false,
      });
      if (this.state.selectedType === 'Podcasts') {
        this.setState({
          selectedType: 'All Media Types',
        });
      }
    } else {
      this.setState({
        hasPodcasts: true,
      });
    }
    if (get(onlineProgramsData, 'hits').length === 0) {
      this.setState({
        hasPrograms: false,
      });
      if (this.state.selectedType === 'Online Programs') {
        this.setState({
          selectedType: 'All Media Types',
        });
      }
    } else {
      this.setState({
        hasPrograms: true,
      });
    }
  };

  selectedType = (data) => {
    this.setState({
      selectedType: data,
      showDropDownTypes: false,
      showDropDownTopic: false,
    });
  };

  toggleDropDownTopic = () => {
    this.setState((prevState) => ({
      showDropDownTopic: !prevState.showDropDownTopic,
      showDropDownTypes: false,
    }));
  };

  toggleDropDownTypes = () => {
    this.setState((prevState) => ({
      showDropDownTypes: !prevState.showDropDownTypes,
      showDropDownTopic: false,
    }));
  };

  redirect = () => {
    const { selectedTopicSlug, selectedType, rootUrl } = this.state;
    let typesFilter;
    if (selectedType !== 'All Media Types') {
      typesFilter = `types=${selectedType}`;
    } else {
      const types = ITEMS.filter(
        (type) => !['All Media Types'].includes(type.value),
      ).map((item) => item.value);
      typesFilter = qs.stringify({ types });
    }
    const url = `${rootUrl}/topics/${selectedTopicSlug}?${typesFilter}`;
    window.open(url, '_newtab');
  };
  render() {
    const {
      showDropDownTopic,
      selectedTopic,
      showDropDownTypes,
      selectedType,
      topics,
      hasApps,
      hasPodcasts,
      hasPrograms,
    } = this.state;
    const {
      buttonBGColor,
      buttonTextColor,
      sentenceJoin = 'Through',
      sentencePrompt = 'Help me with',
      submitText = 'GO',
      helpMeTextColor,
      shortName,
    } = this.props;
    if (topics.length === 0) return null;
    return (
      <Container>
        <Wrapper>
          <Label color={helpMeTextColor} aria-label={sentencePrompt}>{sentencePrompt}</Label>
          <div>
            <Selector
              clickHandler={this.toggleDropDownTopic}
              value={selectedTopic}
              open={showDropDownTopic}
              color={helpMeTextColor}
            />
            <DropDownTopics
              open={showDropDownTopic}
              data={topics}
              clickHandler={this.selectTopic}
              selected={selectedTopic}
              name={shortName}
            />
          </div>

          <Label color={helpMeTextColor} aria-label={sentenceJoin}>{sentenceJoin}</Label>
          <div>
            <Selector
              clickHandler={this.toggleDropDownTypes}
              value={selectedType}
              open={showDropDownTypes}
              color={helpMeTextColor}
            />
            <DropDownTypes
              open={showDropDownTypes}
              clickHandler={this.selectedType}
              selected={selectedType}
              hasApps={hasApps}
              hasPodcasts={hasPodcasts}
              hasPrograms={hasPrograms}
              name={shortName}
            />
          </div>
        </Wrapper>
        <Button
          onClick={this.redirect}
          btnBGColor={buttonBGColor}
          btnColor={buttonTextColor}
        >
          {submitText}
        </Button>
      </Container>
    );
  }
}
