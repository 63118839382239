import { h, render, Component } from 'preact';
import styled from '@emotion/styled';
import { hasHash } from '../../utils/propsChecker';

const Root = styled.header`
  padding: 22px 22px 0 22px;
`;

const Title = styled.span`
  color: ${(props) => {
    const propsHasHash = hasHash(props.color);
    if (props.color && propsHasHash) {
      return props.color;
    } else if (props.color && !propsHasHash) {
      return `#${props.color}`;
    } else {
      return '#01619b';
    }
  }};
  font-size: 17px;
  margin: 0;
  width: 100%;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Mada' !important;
  line-height: 20px !important;
`;

export default class Header extends Component {
  render({ title, color }) {
    return (
      <Root>
        <Title color={color}>{title}</Title>
      </Root>
    );
  }
}
