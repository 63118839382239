const AllIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/All.svg';
const AppsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Apps.svg';
const ArticlesIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Articles.svg';
const BooksIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Books.svg';
const OrganizationsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Organizations.svg';
const PeopleIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/People.svg';
const PodcastsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Podcasts.svg';
const VideosIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Videos.svg';
const ProgramsIcon =
  'https://widget.crediblemind.com/assets/resource-icons/Black/Programs.svg';
const AllIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/All.svg';
const AppsIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/Apps.svg';
const ArticlesIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/Articles.svg';
const BooksIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/Books.svg';
const OrganizationsIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/Organizations.svg';
const PeopleIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/People.svg';
const PodcastsIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/Podcasts.svg';
const VideosIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/Videos.svg';
const ProgramsIconActive =
  'https://widget.crediblemind.com/assets/resource-icons/Blue/Programs.svg';

export const ITEMS = [
  {
    label: 'All Media Types',
    value: 'All Media Types',
    icon: [AllIcon, AllIconActive],
  },
  {
    label: 'Apps',
    value: 'Apps',
    icon: [AppsIcon, AppsIconActive],
  },
  {
    label: 'Podcasts',
    value: 'Podcasts',
    icon: [PodcastsIcon, PodcastsIconActive],
  },
  {
    label: 'Videos',
    value: 'Videos',
    icon: [VideosIcon, VideosIconActive],
  },
  {
    label: 'Articles',
    value: 'Articles',
    icon: [ArticlesIcon, ArticlesIconActive],
  },
  {
    label: 'Books',
    value: 'Books',
    icon: [BooksIcon, BooksIconActive],
  },
  {
    label: 'Organizations',
    value: 'Organizations',
    icon: [OrganizationsIcon, OrganizationsIconActive],
  },
  {
    label: 'People',
    value: 'People',
    icon: [PeopleIcon, PeopleIconActive],
  },
  {
    label: 'Online Programs',
    value: 'Online Programs',
    icon: [ProgramsIcon, ProgramsIconActive],
  },
];
