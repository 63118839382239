import styled from '@emotion/styled';

export default styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.searchOnly ? '28px' : 0)};
  position: relative;
  height: 39px;
  /* position: relative; */
  .react-autosuggest__input {
    margin: 0 !important;
    height: 39px;
    width: 100%;
    max-width: 100% !important;
    font-size: 14px !important;
    font-family: 'Mada' !important;
    border: 1px solid #e3e3e3;
    border-radius: 20px !important;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding: 10px 17px !important;
    :focus {
      outline: none;
    }
  }
  .react-autosuggest__input::-ms-clear {
    display: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 38px;
    flex-grow: 5;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-radius: 0;
    z-index: 100;
    padding: 0 !important;
    margin: 0 !important;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
  }

  .react-autosuggest__suggestions-list {
    margin: 0 !important;
    padding: 0 !important;
    list-style-type: none !important;
    text-align: left !important;
  }

  .react-autosuggest__suggestions-list li {
    list-style-type: none !important;
    text-align: left !important;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 15px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #eee;
  }
  .react-autosuggest__section-title {
    text-align: left !important;
  }

  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
  }

  .react-autosuggest__section-container--first {
    border-top: 0;
  }
`;
