import styled from '@emotion/styled';
import { hasHash } from '../../utils/propsChecker';

export default styled.span`
  color: ${(props) => {
    const propsHasHash = hasHash(props.color);
    if (props.color && propsHasHash) {
      return props.color;
    } else if (props.color && !propsHasHash) {
      return `#${props.color}`;
    } else {
      return '#01619b';
    }
  }};
  font-size: 17px;
  margin: 0 0 16px 0;
  width: 100%;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
