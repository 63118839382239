import Config from './config';
import algoliasearch from 'algoliasearch';

const AlgoliaClient = (env) => {
  const {
    ALGOLIA: { APPLICATION_ID, SEARCH_API_KEY },
  } = Config[env.toUpperCase() || 'PROD'];
  return algoliasearch(APPLICATION_ID, SEARCH_API_KEY);
};
export default AlgoliaClient;
