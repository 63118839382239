import styled from '@emotion/styled';
import { hasHash } from '../../utils/propsChecker';

export default styled.div`
  font-family: 'Mada', sans-serif;
  background: ${(props) => {
    const propsHasHash = hasHash(props.bgColor);
    if (props.bgColor && propsHasHash) {
      return props.bgColor;
    } else if (props.bgColor && !propsHasHash) {
      return `#${props.bgColor}`;
    } else {
      return '#fff';
    }
  }};
  border-top: ${(props) => {
    const propsHasHash = hasHash(props.stripeColor);
    if (props.stripeColor && propsHasHash) {
      return `6px solid ${props.stripeColor} !important`;
    } else if (props.stripeColor && !propsHasHash) {
      return `6px solid #${props.stripeColor} !important`;
    } else {
      return 'none';
    }
  }};
  position: relative;
  height: ${(props) =>
    props.height && props.height !== 'auto' ? `${props.height}px` : 'auto'};
  width: ${(props) => {
    if (props.responsive) {
      return '100%';
    } else if (props.width) {
      return `${parseInt(props.width)}px`;
    } else {
      return '340px';
    }
  }};
  border: 1px solid #efefef;
  border-radius: 2px;
`;
