import styled from '@emotion/styled';
import { hasHash } from '../../utils/propsChecker';
export default styled.label`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Mada' !important;
  color: ${(props) => {
    const propsHasHash = hasHash(props.color);
    if (props.color && propsHasHash) {
      return props.color;
    } else if (props.color && !propsHasHash) {
      return `#${props.color}`;
    } else {
      return '#4b4b4b';
    }
  }};
  margin-top: 5px;
  width: 100%;
`;
