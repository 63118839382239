import styled from '@emotion/styled';
import { hasHash } from '../../utils/propsChecker';

export default styled.button`
  width: 100%;
  height: 40px;
  margin-bottom: 32px;
  background: ${(props) => {
    const propsHasHash = hasHash(props.btnBGColor);
    if (props.btnBGColor && propsHasHash) {
      return props.btnBGColor;
    } else if (props.btnBGColor && !propsHasHash) {
      return `#${props.btnBGColor}`;
    } else {
      return '#0862c0';
    }
  }};
  border-radius: 20px;
  color: ${(props) => {
    const propsHasHash = hasHash(props.btnColor);
    if (props.btnColor && propsHasHash) {
      return props.btnColor;
    } else if (props.btnColor && !propsHasHash) {
      return `#${props.btnColor}`;
    } else {
      return '#fff';
    }
  }};
  font-size: 14px;
  box-shadow: none;
  padding: 0;
  border-color: transparent;
  :focus {
    outline: none;
  }
`;
