export const GET_WIDGET_DATA = /* GraphQL */ `
  query Widgets($slug: String!) {
    widgetsCollection(where: { slug: $slug }, limit: 1) {
      items {
        slug
        type
        design
        client {
          name
          url
          shortName
          excludeTopicCollection {
            items {
              title
              slug
              sys {
                id
              }
            }
          }
          logoReverse {
            url
          }
        }
        topTopicsCollection {
          items {
            slug
            title
          }
        }
      }
      
    }
  }
`;
