import { h, render, Component } from 'preact';
import styled from '@emotion/styled';

const Root = styled.header`
  height: 96px;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 22px;
`;

const LogoWrapper = styled.div`
  height: 57px;
  width: 57px;
  border: 1px solid #efefef;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  margin-right: 19px;
`;
const Logo = styled.img`
  height: auto;
  width: 100%;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Name = styled.span`
  font-size: 17px;
  color: #4b4b4b;
  font-weight: 700;
  margin: 0;
`;
const Url = styled.a`
  text-decoration: none;
  font-size: 14px;
  color: #4b4b4b;
`;
export default class Header extends Component {
  render({ data }) {
    const { logoReverse, name, url } = data;
    const finalUrl =  url
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '')
    .split(/[/?#]/)[0];
    return (
      <Root>
        <Content>
          <LogoWrapper>
            <Logo src={logoReverse.url} alt={name} />
          </LogoWrapper>
          <InfoWrapper>
            <Name>{name}</Name>
            <Url href={url} target="_blank" rel="noopener">
              {finalUrl}
            </Url>
          </InfoWrapper>
        </Content>
      </Root>
    );
  }
}
